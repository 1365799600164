<template>
    <div>
        <el-dialog title="免审设置" v-model="dialogVisible" :width="isPlatform?'60%':'30%'" >
            <template v-if="isPlatform">
                <avue-crud ref="crud" :page="page" :data="tableData"
                    :table-loading="tableLoading" :option="tableOption"
                    @search-change="searchChange" @refresh-change="refreshChange" @size-change="sizeChange"
                    @current-change="currentChange" @row-del="rowDel" @row-update="rowUpdate">
                    <template #menu-left>
                        <el-button @click="addFun"  type="primary">添加</el-button>
                    </template>
                </avue-crud>
                    <el-dialog v-model="innerVisible" width="30%" title="添加免审" append-to-body >
                        <avue-form :option="optionBind" v-model="fromData" @submit="submit" ref="avueForm1"></avue-form>
                    </el-dialog>
            </template>
            <template v-else>
                <avue-form :option="optionBindShop" v-model="fromData" @submit="submit" ref="avueForm"></avue-form>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
    import { defineExpose,reactive,ref,getCurrentInstance } from 'vue'
    import { userStore } from '@/store/user'
    import Api_ from '@/api/index'
    import { ElNotification,ElMessageBox } from 'element-plus'
    import { tableOptionExamine,optionBindExamine,optionBindExamineShop } from "@/const/crud/cryptocurrency/currencySet";
    const { $t } = getCurrentInstance().proxy;

    const isPlatform = ref(null);
    const dialogVisible = ref(false);
    const tableLoading = ref(false);
    const tableData = ref([])
    const searchForm = ref({})
    const page = reactive({
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
    })
    const tableOption = ref(tableOptionExamine)
    const fromData = ref({})
    const optionBind = ref(optionBindExamine)
    const optionBindShop = ref(optionBindExamineShop)
    const innerVisible = ref(false)
    const avueForm1 = ref(null)

    const addFun = ()=>{
        innerVisible.value = true
        if(avueForm1.value){
            avueForm1.value.resetForm()
        }
    }
    const rowUpdate = (row,index,done)=>{
        Api_.editWithdrawCoinExemption(row).then(res=>{
            if(res.code === 0 ){
                getList()//要区分
                ElNotification({
                    message: '修改成功',
                    type: 'success',
                })
            }else{
                ElNotification({
                    message: res.msg,
                    type: 'error',
                })
            }
        })
        done()
    }
    const submit = (form,done)=>{
        if(fromData.value.id){
            Api_.editWithdrawCoinExemption(fromData.value).then(res=>{
                if(res.code === 0 ){
                    getList()//要区分
                    ElNotification({
                        message: '修改成功',
                        type: 'success',
                    })
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                }
            })
        }else{
            Api_.addWithdrawCoinExemption(fromData.value).then(res=>{
                if(res.code === 0 ){
                    getList()//要区分
                    ElNotification({
                        message: '添加成功',
                        type: 'success',
                    })
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                }
            })

        }
        innerVisible.value = false
        done()
    }
    const rowDel = (e)=>{
        ElMessageBox.confirm($t('alert.selEnter'), $t('tyle_.tits'), {
            confirmButtonText: $t('button.enter'),
            cancelButtonText: $t('button.close'),
            type: 'warning',
        }).then(()=>{
            Api_.delWithdrawCoinExemption(e.id).then(res=>{
                if(res.code === 0 ){
                    getList()
                    ElNotification({
                        message: $t('alert.succDel'),
                        type: 'success',
                    })
                }else{
                    ElNotification({
                        message: res.msg?res.msg:$t('alert.delText'),
                        type: 'error',
                    })
                }
            })
        })
    }
    const getList = ()=>{
        let obj_ = {
            pageNum: page.currentPage,
            pageSize: page.pageSize,
            ...searchForm.value
        }
        tableLoading.value = true
        Api_.pageWithdrawCoinExemption(obj_).then(res=>{
            if(res.code === 0){
                tableLoading.value = false
                tableData.value = res.data.records
                page.total = res.data.total
            }else{
                ElNotification({
                    message: res.msg,
                    type: 'error',
                })
            }
        })

    }
    const searchChange = (form, done)=>{
        searchForm.value = form;
        page.currentPage = 1;
        getList();
        done();
    }
    const refreshChange = ()=>{} 
    const sizeChange = (size)=>{
        page.currentPage = 1;
        page.pageSize = size;getList();
    }
    const currentChange = (current)=>{
        page.currentPage = current;
        getList();
    }
    const init = ()=>{
        isPlatform.value = userStore().getIsPlatform
        // isPlatform.value = false
        dialogVisible.value = true;
        if(!isPlatform.value){
            Api_.pageWithdrawCoinExemption({pageNum:1,pageSize:10}).then(res=>{
                if(res.code === 0){
                    if(res.data.records.length === 1){
                        fromData.value = {...res.data.records[0],tenantId:userStore().getUserInfo[0].tenantId}
                    }else{
                        fromData.value = {}
                    }
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                }
            })
        }else{
            getList();
        }
    }

    defineExpose({init})
</script>

<style lang="scss" scoped>
    .avue-dialog .el-dialog__body{
        height: auto;
    }
</style>