import il8n from '@/assets/language/index.js';
import website from '@/config/website'
const { t } = il8n.global;

const validatePositiveInteger = (rule,value,callback)=>{
    const intRegex = /^\d+$/;  
    if (value === '' || !intRegex.test(value)) {  
        callback(new Error("请输入正整数"))
    }  
    callback()
}
export const tableOption1 = ()=> {
    return {
    border: true,
    stripe: true,
    highlightCurrentRow	: true,
    align: 'center',
    editBtn: false,
    delBtn: false,
    addBtn: false,
    viewBtn: false,
    refreshBtn: false,
    columnBtn: false,
    height: 'auto',
    menuWidth: 200,
    menu: false,
    column: [ {
        width: 130,
        label: t('currencySet.name1'),
        prop: 'tenantName'
    },{
        width: 100,
        label: t('currencySet.name2'),
        prop: 'appName'
    },{
        width:100,
        label: t('currencySet.text10'),
        prop: 'chain'
    },
    {
        width: 100,
        label: t('currencySet.text11'),
        prop: 'protocol',
    },
    {
        width: 100,
        label: t('currencySet.text1'),
        prop: 'coin',
    },{
        width: 200,
        overHidden: true,
        label: t('currencySet.name4'),
        prop: 'contractAddr'
    },{
        width: 200,
        label: t('currencySet.name5'),
        prop: 'collectionWaterline',
    },{
        width: 200,
        label: t('currencySet.name6'),
        prop: 'hotWalletToplimit',
    },{
        width: 200,
        label: t('currencySet.name7'),
        prop: 'hotWalletWarnLimit',
    },{
        width: 200,
        label: t('currencySet.name8'),
        prop: 'gasFeeWarnLimit',
    },{
        width: 200,
        label: t('currencySet.name9'),
        prop: 'coldWalletToplimit',
    },{
        width: 200,
        label: t('currencySet.name10'),
        prop: 'minCollectionAmount',
    },{
        width: 200,
        label: t('currencySet.name12'),
        prop: 'minWithdrawAmount',
    },{
        width: 200,
        label: t('currencySet.name13'),
        prop: 'maxWithdrawAmountOnce',
    },{
        width: 200,
        label: t('currencySet.name14'),
        prop: 'maxWithdrawAmountDay',
    },{
        width: 150,
        label: t('currencySet.name16'),
        prop: 'dayStartTime',
    },{
        width: 150,
        label: t('currencySet.name17'),
        prop: 'dayEndTime',
    },{
        width: 200,
        label: t('currencySet.name18'),
        prop: 'dayOnceAuditFree',
    },{
        width: 200,
        label: t('currencySet.name19'),
        prop: 'dayOneAuditNumLimit',
    },{
        width: 200,
        label: t('currencySet.name20'),
        prop: 'dayOneAuditAmountLimit',
    },{
        width: 200,
        label: t('currencySet.name21'),
        prop: 'dayAllAuditAmountLimit',
    },{
        width: 150,
        label: t('currencySet.name23'),
        prop: 'nightStartTime',
    },{
        width: 150,
        label:  t('currencySet.name24'),
        prop: 'nightEndTime',
    },{
        width: 100,
        label:  t('currencySet._name11'),
        prop: 'isCollection',
        type: 'radio',
        dicData: [{
            label: t('currencySet._name11_2'),
            value: 0
        },{
            label: t('currencySet._name11_1'),
            value: 1
        },]
    },{
        width: 200,
        label:  t('currencySet.name25'),
        prop: 'nightOnceAuditFree',
    },{
        width: 200,
        label:  t('currencySet.name26'),
        prop: 'nightOneAuditNumLimit',
    },{
        width: 200,
        label: t('currencySet.name27'),
        prop: 'nightOneAuditAmountLimit',
    },{
        width: 200,
        label: t('currencySet.name28'),
        prop: 'nightAllAuditAmountLimit',
    },{
        width: 200,
        label: t('currencySet.name11'),
        prop: 'isLimit',
        type: 'radio',
        dicData: [{
            label: t('currencySet.name11_1'),
            value: 1
        }, 
         {
            label: t('currencySet.name11_2'),
            value: 0
        },]
    },{
        label: t('currencySet.text12'),
        prop: 'status',
        // value: 1,
        type: 'radio',
        border: true,
        dicData: [{
            label: t('currencySet.name11_1'),
            value: 1
        }, 
         {
            label: t('currencySet.name11_2'),
            value: 0
        },]
    },
    {
        width:170,
        label: t('currencySet.text13'),
        prop: 'createTime',
    }, 
    {
        width:170,
        label: t('currencySet.text14'),
        prop: 'updateTime',
    },{
        width: 110,
        label: t('currencySet.text15'),
        prop: 'updateUser',
        editDisplay: false,
        addDisplay: false,
    },{
        width: 170,
        label: t("button.operation"),
        prop: 'caozuo',
        placeholder: t("inputTit.shuru"),
        fixed:'right',
      }]
        
    }
}
export const tableOptionExamine = {
    border: true,
    stripe: true,
    columnBtn: false,
    refreshBtn: false,
    searchMenuSpan: 12,
    align: 'center',
    menu: true,
    addBtn: false,
    dialogWidth: '30%',
    menuWidth: 150,
    cellBtn: true,
    // labelPosition: 'top',
    column: [{
        label: '商户',
        prop: 'tenantId',
        search:true,
        span: 24,
        type: 'select',
        dicUrl: `${website.urls}/admin/tenant/page`,
        // searchLabelPosition: 'left',
        dicFormatter: (res)=>{
            return res.records
        },
        dicQuery: {pageIndex:1,pageSize: 1000},
        props: {
          label: 'name',
          value: 'id'
        },
        filterable: true,
        cell: true,
        rules: [{
            required: true,
            message: "请选择",
            trigger: "blur"
        }],
    },{
        label: '单客户每日限次',
        prop: 'dayTimes',
        span: 24,
        width: 130,
        cell: true,
        rules: [{
            required: true,
            message: "请填写",
            trigger: "blur"
        },{
            validator: validatePositiveInteger,
            message: '请输入正整数'
        }],
    },{
        label: '单客户每日额度(USDT)',
        prop: 'dayQuota',
        span: 24,
        width: 170,
        cell: true,
        rules: [{
            required: true,
            message: "请填写",
            trigger: "blur"
        },{
            validator: validatePositiveInteger,
            message: '请输入正整数'
        }],
    },{
        label: '单客户每日限次(只统计免审核)',
        prop: 'dayExemptionTimes',
        cell: true,
        span: 24,
        width: 120,
        rules: [{
            required: true,
            message: "请填写",
            trigger: "blur"
        },{
            validator: validatePositiveInteger,
            message: '请输入正整数'
        }],
    },{
        label: '单客户每日额度(USDT)(只统计免审核)',
        prop: 'dayExemptionQuota',
        span: 24,
        width: 170,
        cell: true,
        rules: [{
            required: true,
            message: "请填写",
            trigger: "blur"
        },{
            validator: validatePositiveInteger,
            message: '请输入正整数'
        }],
    },
],
}
export const optionBindExamine = {
    labelPosition: 'top',
    emptyBtn: false,
    column: [{
        label: '商户',
        prop: 'tenantId',
        span: 24,
        type: 'select',
        dicUrl: `${website.urls}/admin/tenant/page`,
        dicFormatter: (res)=>{
            return res.records
        },
        dicQuery: {pageIndex:1,pageSize: 1000},
        props: {
          label: 'name',
          value: 'id'
        },
        filterable: true,
        rules: [{
            required: true,
            message: "请选择",
            trigger: "blur"
        }],
    },{
        label: '单客户每日限次',
        prop: 'dayTimes',
        span: 24,
        rules: [{
            required: true,
            message: "请填写",
            trigger: "blur"
        },{
            validator: validatePositiveInteger,
            message: '请输入正整数'
        }],
    },{
        label: '单客户每日额度(USDT)',
        prop: 'dayQuota',
        span: 24,
        rules: [{
            required: true,
            message: "请填写",
            trigger: "blur"
        },{
            validator: validatePositiveInteger,
            message: '请输入正整数'
        }],
    },{
        label: '单客户每日限次(只统计免审核)',
        prop: 'dayExemptionTimes',
        span: 24,
        rules: [{
            required: true,
            message: "请填写",
            trigger: "blur"
        },{
            validator: validatePositiveInteger,
            message: '请输入正整数'
        }],
    },{
        label: '单客户每日额度(USDT)(只统计免审核)',
        prop: 'dayExemptionQuota',
        span: 24,
        rules: [{
            required: true,
            message: "请填写",
            trigger: "blur"
        },{
            validator: validatePositiveInteger,
            message: '请输入正整数'
        }],
    },
],
}
export const optionBindExamineShop = {
    labelPosition: 'top',
    emptyBtn: false,
    column: [{
        label: '单客户每日限次',
        prop: 'dayTimes',
        span: 24,
        rules: [{
            required: true,
            message: "请填写",
            trigger: "blur"
        },{
            validator: validatePositiveInteger,
            message: '请输入正整数'
        }],
    },{
        label: '单客户每日额度(USDT)',
        prop: 'dayQuota',
        span: 24,
        rules: [{
            required: true,
            message: "请填写",
            trigger: "blur"
        },{
            validator: validatePositiveInteger,
            message: '请输入正整数'
        }],
    },{
        label: '单客户每日限次(只统计免审核)',
        prop: 'dayExemptionTimes',
        span: 24,
        rules: [{
            required: true,
            message: "请填写",
            trigger: "blur"
        },{
            validator: validatePositiveInteger,
            message: '请输入正整数'
        }],
    },{
        label: '单客户每日额度(USDT)(只统计免审核)',
        prop: 'dayExemptionQuota',
        span: 24,
        rules: [{
            required: true,
            message: "请填写",
            trigger: "blur"
        },{
            validator: validatePositiveInteger,
            message: '请输入正整数'
        }],
    },
],
}